import {addRouteMiddleware} from "nuxt/app";

export default defineNuxtRouteMiddleware(async to => {

    const {$directus} = useNuxtApp();
    let router = useRouter();
    const routes = [];

    const producten = await $directus.items('Producten').readByQuery({
        filter: {actief: {_eq: true}},
        fields: ['slug', 'categorie.slug'],
    })
    const productRoutes = producten.data.map((product) => {
        return {
            route: '/shop/' + product.categorie.slug + '/' + product.slug,
            payload: product
        }
    })

    routes.push(...productRoutes)
    const categorieen = await $directus.items('categorieen').readByQuery({
        filter: {actief: {_eq: true}},
        fields: ['slug', 'hoofdcategorie.slug'],
    })
    const categorieRoutes = categorieen.data.map((categorie) => {
        return {
            route: '/shop/' + categorie.slug,
            payload: categorie
        }
    })
    routes.push(...categorieRoutes)
})